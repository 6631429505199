import React, { ReactElement, useEffect, useState } from 'react';
import { Page } from '../Common/Navigation';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../components/buttons';
import {
  CompassIcon,
  Copy,
  EllipsisVertical,
  History,
  Plus,
  TrashIcon,
  X,
} from 'lucide-react';
import { Table } from '../../components/Table';
import { DeadEnd } from '../../components/DeadEnd';
import { Menu } from '../../components/Menu';
import {
  useWorkflowArchive,
  useWorkflowDuplicate,
  useWorkflowList,
  useWorkflowStopUsing,
} from '../../services/Workflow';
import { Spinner } from '../../components/Spinner';
import { useNavigate } from 'react-router';
import { ConfirmActionDialog } from '../../components/modals';
import {
  trackWorkflowEditorOpened,
  trackWorkflowHistoryOpened,
  trackWorkflowProvideFeedbackButton,
  workflowListViewed,
} from '../../helpers/analytics';
import {
  ToggleButton,
  ToggleButtonGroup,
} from '../../components/ToggleButtonGroup';
import { ListWorkflowsQuery, WorkflowsType } from '../../graphql/operations';
import { Avatar } from '../../components/Avatar';
import { Tooltip } from '../../components/Tooltip';
import { WorkflowDiscoveryModal } from './WorkflowDiscovery';
import { Chip } from '../../components/Chips';

export default function WorkflowList(): ReactElement {
  const intl = useIntl();
  const title = intl.formatMessage({
    defaultMessage: 'My Workflows',
    id: 'KbEin0',
  });
  const [type, setType] = useState(WorkflowsType.ALL);
  const types = React.useMemo(
    () => [
      {
        id: WorkflowsType.ALL,
        name: intl.formatMessage({ defaultMessage: 'All', id: 'zQvVDJ' }),
      },
      {
        id: WorkflowsType.MINE,
        name: intl.formatMessage({ defaultMessage: 'Mine', id: 'snC7V6' }),
      },
      {
        id: WorkflowsType.USED_TEAM,
        name: intl.formatMessage({
          defaultMessage: 'Used from Team',
          id: 'RFyIeZ',
        }),
      },
      {
        id: WorkflowsType.USED_TACTIQ,
        name: intl.formatMessage({
          defaultMessage: 'Used from Tactiq',
          id: 'xiBnVC',
        }),
      },
    ],
    [intl]
  );

  const { data, loading, error } = useWorkflowList({
    input: { type },
  });

  if (error) throw error;

  useEffect(() => {
    if (!loading) {
      if (data?.workflows) {
        workflowListViewed({
          type,
          workflowsCount: data?.workflows?.length,
        });
      }
    }
  }, [data?.workflows, data?.workflows?.length, loading, type]);

  // Discover button is shown in the empty state if there are no used workflows,
  const showDiscoverButton =
    data?.workflows?.length &&
    (type === WorkflowsType.USED_TEAM || type === WorkflowsType.USED_TACTIQ);

  return (
    <Page title={title} grow maxWidth={'lg'}>
      <div className="mb-4 flex flex-wrap items-center justify-between gap-y-4">
        <div>
          <h1 className="mb-2 text-3xl font-bold tracking-tight">{title}</h1>
          <p className="text-slate-500">
            <FormattedMessage
              defaultMessage="Create and manage your workflow automations here"
              id="mgM09X"
            />
          </p>
        </div>
        <div className="flex items-center gap-x-4">
          <ProvideFeedbackButton />
          <CreateWorkflowButton />
        </div>
      </div>
      <div className="my-6 flex flex-wrap items-center justify-between gap-3">
        <ToggleButtonGroup value={type} onChange={setType} style="disconnected">
          {types.map((type) => (
            <ToggleButton key={type.id} value={type.id}>
              {type.name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        {showDiscoverButton ? (
          <div className="flex justify-end">
            <DiscoverButton type={type} />
          </div>
        ) : null}
      </div>

      {!data?.workflows && loading ? (
        <Spinner className="m-auto my-16" size="1.5rem" />
      ) : (
        <WorkflowTable type={type} workflows={data?.workflows} />
      )}
    </Page>
  );
}

function WorkflowTable({
  type,
  workflows,
}: {
  type: WorkflowsType;
  workflows: ListWorkflowsQuery['workflows'];
}): ReactElement {
  const { formatMessage } = useIntl();

  const archive = useWorkflowArchive();
  const stopUsing = useWorkflowStopUsing();
  const [archiveId, setArchiveId] = useState<null | string>(null);
  const [removeId, setRemoveId] = useState<null | string>(null);
  const navigate = useNavigate();

  const isUsedType = [
    WorkflowsType.USED_TEAM,
    WorkflowsType.USED_TACTIQ,
  ].includes(type);

  const data = workflows?.filter(
    (workflow) => !workflow.isArchived && (isUsedType ? workflow.isUsed : true)
  );

  if (!data || data?.length === 0) {
    return <EmptyState type={type} />;
  }

  return (
    <>
      <Table
        initialPageSize={100}
        onRowClick={(row) => navigate(`/workflows/${row.id}`)}
        columns={[
          {
            accessorKey: 'name',
            enableResizing: true,
            header: formatMessage({ defaultMessage: 'Name', id: 'HAlOn1' }),
            enableSorting: true,
            cell: ({ row }) => (
              <a
                className="sticky max-w-[14rem] truncate text-sm font-medium text-slate-700 group-hover/row:underline lg:max-w-[22rem] xl:max-w-[28rem]"
                onClick={() => {
                  trackWorkflowEditorOpened({ workflowId: row.id });
                  navigate(`/workflows/${row.original.id}`);
                }}
              >
                {row.original.name}
              </a>
            ),
          },
          {
            accessorKey: 'steps',
            enableSorting: false,
            header: formatMessage({ defaultMessage: 'Steps', id: 'huCHmk' }),
            cell: ({ row }) => (
              <Tooltip
                arrow
                placement="top"
                title={
                  <FormattedMessage
                    defaultMessage="{count} steps"
                    values={{ count: row.original.definition.nodes.length }}
                    id="2GQj+0"
                  />
                }
              >
                <div className="inline-flex rounded-full border border-slate-200 bg-white px-2 py-0.5 text-xs font-medium text-slate-500">
                  {row.original.definition.nodes.length}
                </div>
              </Tooltip>
            ),
          },
          {
            accessorKey: 'owner',
            enableResizing: true,
            header: formatMessage({ defaultMessage: 'Owner', id: 'zINlao' }),
            enableSorting: true,
            cell: ({ row }) => (
              <span className="flex shrink-0 items-center gap-2 text-sm text-slate-500">
                <Avatar
                  name={row.original.createdBy.displayName}
                  src={row.original.createdBy.avatarUrl}
                  size="sm"
                />
                {row.original.createdBy.displayName}
              </span>
            ),
          },
          {
            accessorKey: 'triggerType',
            header: formatMessage({
              defaultMessage: 'Trigger type',
              id: '6Vngei',
            }),
            enableSorting: true,
            enableResizing: true,
            size: 100,
            maxSize: 150,
            cell: () => (
              <div className="inline-flex rounded-full border border-slate-200 bg-white px-2 py-0.5 text-xs font-medium text-slate-500">
                Manual
              </div>
            ),
          },
          {
            accessorKey: 'integrations',
            enableSorting: true,
            header: formatMessage({
              defaultMessage: 'Integrations',
              id: '6cBDhe',
            }),
            cell: ({ row }) => (
              <div className="flex gap-2">
                {row.original.integrations.map((intg) => (
                  <Chip key={intg} color="slate">
                    {intg}
                  </Chip>
                ))}
              </div>
            ),
          },
          {
            id: 'actions',
            meta: {
              stopEventPropagation: true,
              align: 'right',
            },
            size: 80,
            cell: ({ row }) => (
              <div className="sticky inline-flex items-center justify-end gap-x-3">
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <FormattedMessage defaultMessage="History" id="djJp6c" />
                  }
                >
                  <Button
                    variant="icon"
                    size="small"
                    onClick={() => {
                      trackWorkflowHistoryOpened({ workflowId: row.id });
                      navigate(`/workflows/${row.original.id}/activity`);
                    }}
                  >
                    <History size="1rem" />
                  </Button>
                </Tooltip>
                <DuplicateWorkflowButton id={row.original.id} />

                <Menu>
                  <Menu.Trigger>
                    <Button variant="naked">
                      <EllipsisVertical size="1rem" />
                    </Button>
                  </Menu.Trigger>

                  {isUsedType ? (
                    <Menu.Item
                      icon={<X size="1rem" />}
                      className="text-red-500"
                      onClick={() => {
                        setRemoveId(row.original.id);
                      }}
                    >
                      <FormattedMessage
                        defaultMessage="Stop using"
                        id="RIceT3"
                      />
                    </Menu.Item>
                  ) : (
                    <Menu.Item
                      icon={<TrashIcon size="1rem" />}
                      className="text-red-500"
                      onClick={() => {
                        setArchiveId(row.original.id);
                      }}
                      disabled={!row.original.canModify}
                    >
                      <FormattedMessage
                        defaultMessage="Delete Workflow"
                        id="LzXP/m"
                      />
                    </Menu.Item>
                  )}
                </Menu>
              </div>
            ),
          },
        ]}
        data={data}
      />

      {archiveId && (
        <ConfirmActionDialog
          open
          title={
            <FormattedMessage defaultMessage="Delete workflow" id="RPw47l" />
          }
          text={
            <FormattedMessage
              defaultMessage="Are you sure you want to delete this workflow?"
              id="dqGjs3"
            />
          }
          yes={<FormattedMessage defaultMessage="Delete" id="K3r6DQ" />}
          yesProps={{ loading: archive.loading, color: 'error' }}
          onYes={async () => {
            await archive.request({
              input: { id: archiveId, isArchived: true },
            });
            setArchiveId(null);
          }}
          onNo={() => setArchiveId(null)}
        />
      )}

      {removeId && (
        <ConfirmActionDialog
          open
          title={
            <FormattedMessage
              defaultMessage="Stop using workflow"
              id="N9eLq8"
            />
          }
          text={
            <FormattedMessage
              defaultMessage="Are you sure you want to stop using this workflow?"
              id="549m56"
            />
          }
          yes={<FormattedMessage defaultMessage="Yes" id="a5msuh" />}
          yesProps={{ loading: stopUsing.loading, color: 'error' }}
          onYes={async () => {
            await stopUsing.request({
              input: { id: removeId },
            });
            setRemoveId(null);
          }}
          onNo={() => setRemoveId(null)}
        />
      )}
    </>
  );
}

function EmptyState(props: { type: WorkflowsType }) {
  const { formatMessage } = useIntl();
  const { type } = props;
  let title;
  let cta;
  if (type === WorkflowsType.USED_TEAM) {
    title = formatMessage({
      defaultMessage: 'Discover workflows from your team',
      id: 'DShQqI',
    });
    cta = <DiscoverButton type={WorkflowsType.USED_TEAM} variant="filled" />;
  } else if (type === WorkflowsType.USED_TACTIQ) {
    title = formatMessage({
      defaultMessage: 'Discover workflows from Tactiq',
      id: 'iz7RGl',
    });
    cta = <DiscoverButton type={WorkflowsType.USED_TACTIQ} variant="filled" />;
  } else {
    title = formatMessage({
      defaultMessage: 'Create your first workflow',
      id: 'LhWBrL',
    });
    cta = <CreateWorkflowButton />;
  }
  return (
    <div className="mx-auto my-16 max-w-2xl">
      <DeadEnd
        title={title}
        description={formatMessage({
          defaultMessage:
            'Streamline your after meeting workflow by automating tasks. Use Tactiq Al prompts and connect with third-party apps to setup triggers and actions',
          id: 'uW0puE',
        })}
        action={cta}
      />
    </div>
  );
}

function CreateWorkflowButton() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <WorkflowDiscoveryModal open={open} setOpen={setOpen} />
      <Button
        startIcon={<Plus size="1rem" />}
        onClick={() => setOpen(true)}
        size="small"
      >
        <FormattedMessage defaultMessage="New workflow" id="GIHRKG" />
      </Button>
    </>
  );
}

function DiscoverButton(props: {
  type: WorkflowsType.USED_TEAM | WorkflowsType.USED_TACTIQ;
  variant?: 'filled' | 'secondaryOutline';
}) {
  const { type, variant } = props;
  const [open, setOpen] = useState(false);
  let defaultType: WorkflowsType.TEAM | WorkflowsType.TACTIQ;
  if (type === WorkflowsType.USED_TEAM) {
    defaultType = WorkflowsType.TEAM;
  } else {
    defaultType = WorkflowsType.TACTIQ;
  }
  return (
    <>
      <WorkflowDiscoveryModal
        open={open}
        setOpen={setOpen}
        defaultType={defaultType}
      />
      <Button
        variant={variant ?? 'secondaryOutline'}
        startIcon={<CompassIcon size="1rem" />}
        onClick={() => setOpen(true)}
        size="small"
      >
        <FormattedMessage defaultMessage="Discover more" id="n9Vbqi" />
      </Button>
    </>
  );
}

function ProvideFeedbackButton() {
  return (
    <Button
      href="https://tactiq.typeform.com/ai-workflow"
      target="_blank"
      variant="secondaryOutline"
      size="small"
      onClick={() => trackWorkflowProvideFeedbackButton()}
    >
      <FormattedMessage defaultMessage="Provide feedback" id="GdjHV6" />
    </Button>
  );
}

function DuplicateWorkflowButton({ id }: { id: string }) {
  const { data, request, loading } = useWorkflowDuplicate(id);
  const isSuccess = Boolean(data && !loading);
  const navigate = useNavigate();
  const newId = data?.duplicateWorkflow.id;

  useEffect(() => {
    if (isSuccess) {
      navigate(`/workflows/${newId}`);
    }
  }, [newId, isSuccess, navigate]);

  return (
    <Tooltip
      arrow
      placement="top"
      title={
        <FormattedMessage defaultMessage="Duplicate workflow" id="IynmOz" />
      }
    >
      <Button
        variant="icon"
        size="small"
        onClick={() => request()}
        loading={loading}
      >
        {!loading && <Copy size="1rem" />}
      </Button>
    </Tooltip>
  );
}
