import { Condition } from './Condition';
import { Confirmation } from './Confirmation';
import { CustomPrompt } from './CustomPrompt';
import { RunMeetingKit } from './RunMeetingKit';
import { SaveToSpace } from './SaveToSpace';
import { Share } from './Share';
import { StartNode } from './StartNode';
import { Template } from './Template';
import { useIntl } from 'react-intl';

export const nodeTypes = {
  Condition,
  Confirmation,
  CustomPrompt,
  RunMeetingKit,
  SaveToSpace,
  Share,
  StartNode,
  Template,
};

export const useNodeTypeToName: () => Record<
  keyof typeof nodeTypes,
  string
> = () => {
  const intl = useIntl();
  return {
    SaveToSpace: intl.formatMessage({
      defaultMessage: 'Save to space',
      id: 'rCfIl5',
    }),
    RunMeetingKit: intl.formatMessage({
      defaultMessage: 'Use a quick prompt',
      id: '1BPsCT',
    }),
    CustomPrompt: intl.formatMessage({
      defaultMessage: 'Custom prompt',
      id: 'zp4jaB',
    }),
    Share: intl.formatMessage({
      defaultMessage: 'Share to',
      id: 'k/eRpT',
    }),
    Confirmation: intl.formatMessage({
      defaultMessage: 'Confirmation',
      id: 'Pswssl',
    }),
    Condition: intl.formatMessage({
      defaultMessage: 'Condition',
      id: 'XMpO69',
    }),
    StartNode: intl.formatMessage({
      defaultMessage: 'Start',
      id: 'mOFG3K',
    }),
    Template: intl.formatMessage({ defaultMessage: 'Template', id: '3JxaKs' }),
  };
};
