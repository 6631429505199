import React, { ReactElement, useEffect, useState } from 'react';
import { ModalDialog } from '../../components/modals';
import {
  useWorkflowCreate,
  useWorkflowList,
  useWorkflowRun,
} from '../../services/Workflow';
import { FormattedMessage } from 'react-intl';
import { ArrowLeft, ArrowUpRight, FolderHeart, Users } from 'lucide-react';
import { Button } from '../../components/buttons';
import { WorkflowsType } from '../../graphql/operations';
import { WorkflowPreview } from './WorkflowEditor';
import { useNavigate } from 'react-router';
import { RunWorkflowButton } from './RunWorkflowButton';
import { cx } from '../../helpers/utils';
import {
  trackWorkflowBrowsed,
  trackWorkflowPreview,
} from '../../helpers/analytics';
import { Chip } from '../../components/Chips';
import { Skeleton } from '../../components/Skeleton';
import { Tooltip } from '../../components/Tooltip';

/**
 * View the workflow discover page from a modal
 */
export function WorkflowDiscoveryModal(props: {
  open: boolean;
  setOpen: (next: boolean) => void;
  meetingId?: string;
  defaultType?: WorkflowsType.TEAM | WorkflowsType.TACTIQ;
}): ReactElement {
  const { open, setOpen, meetingId, defaultType } = props;
  const [workflowId, setWorkflowId] = useState<string>();

  useEffect(() => {
    if (workflowId) {
      trackWorkflowPreview({ workflowId, source: 'modal' });
    }
  }, [workflowId]);

  useEffect(() => {
    if (open) {
      trackWorkflowBrowsed({ source: 'modal' });
    }
  }, [open]);

  return (
    <ModalDialog open={open} size="xl" onClose={() => setOpen(false)}>
      <WorkflowDiscovery
        workflowId={workflowId}
        setWorkflowId={setWorkflowId}
        meetingId={meetingId}
        setMeetingId={() => {}}
        onSuccess={() => setOpen(false)}
        heightClassName="h-[60vh]"
        selectedType={defaultType}
      />
    </ModalDialog>
  );
}

/**
 * Gives people a way to preview workflows without duplicating or running them.
 */
export function WorkflowDiscovery(props: {
  workflowId: string | undefined;
  setWorkflowId: (next: string | undefined) => void;
  meetingId?: string;
  setMeetingId: (next?: string) => void;
  onSuccess: (next: { meetingId: string; executionId: string }) => void;
  heightClassName?: string;
  selectedType?: WorkflowsType.TEAM | WorkflowsType.TACTIQ;
}): ReactElement {
  const {
    onSuccess,
    meetingId,
    heightClassName,
    workflowId,
    setWorkflowId,
    selectedType,
  } = props;
  const tactiqWorkflowList = useWorkflowList({
    input: { type: WorkflowsType.TACTIQ },
  });
  const teamWorkflowList = useWorkflowList({
    input: { type: WorkflowsType.TEAM },
  });
  const [type, setType] = useState<WorkflowsType>(
    selectedType ?? WorkflowsType.TEAM
  );

  const tabs = [
    {
      value: WorkflowsType.TACTIQ,
      label: <FormattedMessage defaultMessage="Recommended" id="VKfWR3" />,
      icon: FolderHeart,
    },
  ];
  if (teamWorkflowList.data?.workflows?.length) {
    tabs.unshift({
      value: WorkflowsType.TEAM,
      label: <FormattedMessage defaultMessage="Team" id="wsUmh9" />,
      icon: Users,
    });
  }

  useEffect(() => {
    // Only show the team tab if there are team workflows
    if (
      !teamWorkflowList.loading &&
      !teamWorkflowList.data?.workflows?.length
    ) {
      setType(WorkflowsType.TACTIQ);
    }
  }, [teamWorkflowList]);

  const workflows =
    type === WorkflowsType.TACTIQ
      ? tactiqWorkflowList.data?.workflows
      : teamWorkflowList.data?.workflows;

  const preview = workflows?.find((workflow) => workflow.id === workflowId);
  const workflowRun = useWorkflowRun('discovery');
  if (workflowRun.error) throw workflowRun.error;

  if (preview) {
    return (
      <>
        <div className="mb-3 flex justify-between">
          <Button
            variant="secondaryOutline"
            onClick={() => setWorkflowId(undefined)}
            startIcon={<ArrowLeft size="1rem" />}
          >
            <FormattedMessage defaultMessage="Back" id="cyR7Kh" />
          </Button>
          <div className="text-2xl font-semibold">{preview.name}</div>
          <RunWorkflowButton
            meetingId={meetingId}
            workflow={preview}
            onSuccess={onSuccess}
          />
        </div>
        <div
          className={cx(
            'overflow-hidden rounded-lg',
            heightClassName ?? 'h-full'
          )}
        >
          <WorkflowPreview
            nodes={preview.definition.nodes}
            edges={preview.definition.edges}
          />
        </div>
      </>
    );
  }

  const isLoading = teamWorkflowList.loading || tactiqWorkflowList.loading;

  return (
    <>
      <div className="mb-4 flex items-center gap-2">
        <div className="text-2xl font-semibold">
          <FormattedMessage defaultMessage="Workflow Library" id="mDVer5" />
        </div>
      </div>

      <div className="flex flex-col gap-10 overflow-auto @container lg:flex-row">
        <div className="flex flex-shrink-0 flex-col gap-1 lg:w-40">
          {isLoading ? (
            <Skeleton className="h-8" />
          ) : (
            tabs.map(({ value, label, icon: Icon }) => (
              <Button
                key={value}
                variant={type === value ? 'secondary' : 'naked'}
                className={cx(
                  // @todo - our button variants don't fully support Matts designs here.
                  // But refactoring the button would create more changes elsewhere.
                  // This should be replaced when we clean them up
                  type === value ? 'font-semibold text-indigo-800' : '',
                  'w-full'
                )}
                startIcon={<Icon className="size-4" />}
                onClick={() => setType(value)}
              >
                {label}
              </Button>
            ))
          )}
        </div>
        <div
          className={cx(
            'grid w-full auto-rows-min grid-cols-1 gap-4 overflow-auto @md:grid-cols-2 @2xl:grid-cols-3 @7xl:grid-cols-4',
            heightClassName ?? 'h-full'
          )}
        >
          <BlankCard />
          {isLoading ? (
            <Skeleton count={5} className="h-40" />
          ) : (
            workflows?.map((workflow) => {
              return (
                <div
                  key={workflow.id}
                  onClick={() => setWorkflowId(workflow.id)}
                  className="flex cursor-pointer flex-col gap-3 rounded-lg bg-slate-100 p-5 text-lg leading-5"
                >
                  <div className="flex">
                    {workflow.tactiq ? (
                      <Chip color="green" type="pill">
                        <FormattedMessage defaultMessage="Tactiq" id="iTearT" />
                      </Chip>
                    ) : (
                      <Chip color="slate" type="pill">
                        <FormattedMessage defaultMessage="Team" id="wsUmh9" />
                      </Chip>
                    )}
                  </div>
                  <div className="text-md mb-auto line-clamp-3 flex min-h-14 items-start font-semibold">
                    {workflow.name}
                    <Tooltip
                      arrow
                      placement="top"
                      title={
                        <FormattedMessage
                          defaultMessage="{count} steps"
                          values={{
                            count: workflow.definition.nodes.length,
                          }}
                          id="2GQj+0"
                        />
                      }
                    >
                      <div className="ml-2 inline-flex rounded-lg border border-slate-200 bg-white px-2 py-0.5 text-xs font-medium text-slate-500">
                        {workflow.definition.nodes.length}
                      </div>
                    </Tooltip>
                  </div>
                  <div className="flex gap-2">
                    {workflow.integrations.map((intg) => (
                      <Chip key={intg} color="slate">
                        {intg}
                      </Chip>
                    ))}
                  </div>
                  <Button
                    className="mt-3"
                    variant="secondaryOutline"
                    size="small"
                    endIcon={<ArrowUpRight size="1rem" />}
                    onClick={() => setWorkflowId(workflow.id)}
                  >
                    <FormattedMessage defaultMessage="Preview" id="TJo5E6" />
                  </Button>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
}

const BlankCard: React.FC = () => {
  const { data, request, loading } = useWorkflowCreate();
  const isSuccess = Boolean(data && !loading);
  const navigate = useNavigate();
  const id = data?.createWorkflow.id;

  useEffect(() => {
    if (isSuccess) {
      navigate(`/workflows/${id}`);
    }
  }, [id, isSuccess, navigate]);

  return (
    <div
      className="flex cursor-pointer flex-col rounded-lg bg-slate-100 p-5 text-lg leading-5"
      onClick={request}
    >
      <div className="text-md mt-10 line-clamp-3 min-h-8 font-semibold">
        <FormattedMessage
          defaultMessage="Build a workflow from scratch"
          id="m+xMgR"
        />
      </div>
      <Button
        loading={loading}
        className="mt-3"
        variant="secondaryOutline"
        size="small"
        endIcon={<ArrowUpRight size="1rem" />}
      >
        <FormattedMessage defaultMessage="Start" id="mOFG3K" />
      </Button>
    </div>
  );
};
