import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectAiOutputLanguage } from '../../redux/selectors';
import { RawTranscript } from '@tactiq/model';
import { Select } from '../../components/Select';

const supportedLanguages = [
  { value: 'en', label: 'English' },
  { value: 'pt', label: 'Português' },
  { value: 'es', label: 'Español' },
  { value: 'fr', label: 'Français' },
  { value: 'it', label: 'Italiano' },
  { value: 'de', label: 'Deutsch' },
  { value: 'nl', label: 'Nederlands' },
  { value: 'ja', label: '日本語' },
  { value: 'id', label: 'Bahasa Indonesia' },
  { value: 'ru', label: 'Русский' },
  { value: 'pl', label: 'Polski' },
  { value: 'zh', label: '中文' },
  { value: 'ar', label: 'العربية' },
  { value: 'vi', label: 'Tiếng Việt' },
  { value: 'nn', label: 'Norsk nynorsk' },
  { value: 'th', label: 'ไทย' },
  { value: 'tl', label: 'Tagalog' },
  { value: 'ko', label: '한국어' },
  { value: 'tr', label: 'Türkçe' },
  { value: 'hi', label: 'हिन्दी' },
  { value: 'cy', label: 'Cymraeg' },
  { value: 'ca', label: 'Català' },
  { value: 'ms', label: 'Bahasa Melayu' },
  { value: 'uk', label: 'Українська' },
  { value: 'ro', label: 'Română' },
];

type AiOutputLanguageSelectorProps = {
  onChange: (id: string) => void;
  value: string;
  context: 'settings' | 'meeeting';
  disabled?: boolean;
  // Relevant only when used within a meeting; optional otherwise.
  detectedLanguage?: RawTranscript['detectedLanguage'];
  onOpenChange?: (isOpen: boolean) => void;
};

type Option = {
  value: string;
  label: string;
  divider?: 'top' | 'bottom';
};

export const AiOutputLanguageSelector: React.FC<
  AiOutputLanguageSelectorProps
> = ({
  onChange,
  value,
  detectedLanguage,
  disabled,
  context,
  onOpenChange,
}) => {
  const intl = useIntl();
  const defaultLanguage = useSelector(selectAiOutputLanguage);

  const getName = (value: string) =>
    supportedLanguages.find((sl) => sl.value === value)?.label;

  const mainOptions: Option[] = [
    {
      value: 'meeting-language',
      label:
        intl.formatMessage({
          defaultMessage: 'Meeting Language',
          id: '7X2OJt',
        }) +
        (detectedLanguage
          ? ` (${getName(detectedLanguage.shortName.slice(0, 2))})`
          : ''),
    },
  ];

  if (context === 'meeeting' && defaultLanguage !== 'meeting-language') {
    mainOptions.push({
      value: defaultLanguage,
      label: `${getName(defaultLanguage)} (Default)`,
    });
  }

  if (defaultLanguage !== 'en') {
    mainOptions.push({
      value: 'en',
      label: 'English',
    });
  }

  const otherOptions: Option[] = supportedLanguages.filter(
    (x) => mainOptions.map((y) => y.value).indexOf(x.value) === -1
  );
  otherOptions[0] = {
    ...otherOptions[0],
    divider: 'top',
  };

  return (
    <Select<string>
      value={value}
      onChange={onChange}
      options={[...mainOptions, ...otherOptions]}
      disabled={disabled}
      onOpenChange={onOpenChange}
    />
  );
};
